// src/components/Navbar.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear any stored authentication tokens
    localStorage.removeItem('token');
    // Optionally, clear other stored data (e.g., user info)
    // Redirect to the login page
    navigate('/login');
  };

  return (
    <nav className="fixed top-0 left-0 w-full bg-white text-blue-800 px-6 py-3 shadow-md z-50">
      <div className="flex items-center justify-between">
        {/* Brand/Logo */}
        <div className="font-bold text-blue-900 text-2xl cursor-pointer" onClick={() => navigate('/')}>
          Influencer<span className="text-orange-600">Dashboard</span>
        </div>

        {/* Search Bar */}
        <div className="flex-grow mx-4 hidden md:block">
          <div className="relative max-w-md mx-auto">
            <input
              type="text"
              placeholder="Search..."
              className="w-full bg-gray-100 text-sm rounded-full pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <span className="material-icons-outlined absolute left-3 top-2.5 text-gray-400">search</span>
          </div>
        </div>

        {/* Icons and Profile */}
        <div className="flex items-center space-x-4 text-gray-500">
          {/* Notifications */}
          <span className="material-icons-outlined text-3xl cursor-pointer hover:text-gray-700">notifications</span>

          {/* Profile Image */}
          <div
            className="bg-center bg-cover bg-no-repeat rounded-full h-10 w-10 cursor-pointer"
            style={{ backgroundImage: 'url(https://i.pinimg.com/564x/de/0f/3d/de0f3d06d2c6dbf29a888cf78e4c0323.jpg)' }}
          ></div>

          {/* Logout Button */}
          <button
            onClick={handleLogout}
            className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 text-sm"
          >
            Logout
          </button>
        </div>
      </div>

      {/* Mobile Search Bar */}
      <div className="mt-3 md:hidden px-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search..."
            className="w-full bg-gray-100 text-sm rounded-full pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
          <span className="material-icons-outlined absolute left-3 top-2.5 text-gray-400">search</span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
