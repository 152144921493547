import React, { useState, useEffect } from 'react';
import { getAllJobs, applyForJob } from '../api';

const JobPage = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [applyingJobId, setApplyingJobId] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const data = await getAllJobs();
        setJobs(data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
        setError('Failed to fetch jobs. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  const handleApplyForJob = async (jobId) => {
    setApplyingJobId(jobId);
    setSuccessMessage(null);
    try {
      const response = await applyForJob(jobId);
      setSuccessMessage(`You successfully applied for the job: ${response.title || 'Job'}`);
    } catch (error) {
      console.error('Error applying for job:', error.message);
      setError('Failed to apply for the job. Please try again later.');
    } finally {
      setApplyingJobId(null);
    }
  };

  if (loading) {
    return <div className="p-4 text-center text-gray-600">Loading jobs...</div>;
  }

  if (error) {
    return <div className="p-4 text-red-500 text-center">{error}</div>;
  }

  if (jobs.length === 0) {
    return <div className="p-4 text-center text-gray-500">No jobs available at the moment.</div>;
  }

  return (
    <div className="job-page max-w-7xl mx-auto p-6 mt-10">
      {successMessage && (
        <div className="p-4 bg-green-100 text-green-800 rounded-lg shadow-lg mb-6 text-center">
          {successMessage}
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {jobs.map((job) => (
          <div
            key={job._id}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden"
          >
            <div className="relative">
              <img
                className="w-full h-48 object-cover"
                src="https://images.pexels.com/photos/3779448/pexels-photo-3779448.jpeg"
                alt="Job"
              />
            </div>
            <div className="p-4">
              <h3 className="font-bold text-xl mb-2 text-gray-900 dark:text-white">
                {job.title}
              </h3>
              <p className="text-gray-600 dark:text-gray-400 mb-2">
                {job.brand?.companyName || 'Brand Name'}
              </p>
              <p className="text-gray-700 dark:text-gray-300 mb-4">
                {job.description.slice(0, 100)}...
              </p>
              <div className="flex justify-between items-center mb-4 text-sm">
                <div className="text-center">
                  <p className="text-gray-500">Category</p>
                  <p className="font-semibold text-gray-700 dark:text-white">
                    {job.category}
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-gray-500">Budget</p>
                  <p className="font-semibold text-gray-700 dark:text-white">
                    ${job.budget}
                  </p>
                </div>
                <div className="text-center">
                  <p className="text-gray-500">Live Date</p>
                  <p className="font-semibold text-gray-700 dark:text-white">
                    {new Date(job.liveDate).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <button
                onClick={() => handleApplyForJob(job._id)}
                disabled={applyingJobId === job._id}
                className={`w-full font-bold py-2 px-4 rounded ${
                  applyingJobId === job._id
                    ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
                    : 'bg-blue-600 hover:bg-blue-700 text-white'
                } transition-all duration-300`}
              >
                {applyingJobId === job._id ? 'Applying...' : 'Apply Now'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobPage;
