import React, { useState, useEffect, useRef } from 'react';
import { getMessage, postMessage, getProfile } from '../api';

const ChatWindow = ({ influencer, jobId, onClose }) => {
    const [messages, setMessages] = useState([]);
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState(null);
    const messagesEndRef = useRef(null);
    const [isAutoScroll, setIsAutoScroll] = useState(true);

    // Fetch user ID
    useEffect(() => {
        const fetchUserId = async () => {
            try {
                const profileData = await getProfile();
                setUserId(profileData._id);
            } catch (err) {
                console.error('Error fetching profile:', err);
            }
        };
        fetchUserId();
    }, []);

    // Fetch messages
    useEffect(() => {
        const fetchMessages = async () => {
            setLoading(true);
            try {
                const res = await getMessage(jobId);
                setMessages(res);
                scrollToBottom();
            } catch (error) {
                console.error('Error fetching messages:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMessages();
    }, [jobId]);

    const scrollToBottom = () => {
        if (isAutoScroll) {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        setIsAutoScroll(scrollTop + clientHeight >= scrollHeight - 10);
    };

    const sendMessage = async (e) => {
        e.preventDefault();
        if (!content.trim()) return; // Ensure the message is not empty
    
        try {
            const body = { receiverId: influencer._id, jobId, content };
            const res = await postMessage(body);
    
            const newMessage = {
                _id: res._id || Math.random().toString(36).substr(2, 9), // Temporary ID if not provided
                sender: { _id: userId }, // Set the sender to the current user
                content, // Include the message content
                date: new Date().toISOString(), // Add the current timestamp
            };
    
            setMessages((prevMessages) => [...prevMessages, newMessage]); // Update the message list
            setContent(''); // Clear the input field
            scrollToBottom(); // Scroll to the latest message
        } catch (error) {
            console.error('Error sending message:', error.response?.data?.msg || error.message);
        }
    };
    

    return (
        <div className="fixed bottom-4 right-4 w-96 max-w-full h-[70vh] bg-white dark:bg-gray-900 rounded-lg shadow-lg flex flex-col overflow-hidden">
            {/* Chat Header */}
            <div className="flex items-center justify-between p-4 bg-blue-600 text-white">
                <div className="flex items-center">
                    <div className="w-10 h-10 rounded-full bg-blue-800 flex items-center justify-center text-white font-bold mr-3">
                        {influencer.name
                            .split(' ')
                            .map((n) => n[0])
                            .join('')}
                    </div>
                    <h1 className="text-lg font-semibold">{influencer.name}</h1>
                </div>
                <button
                    onClick={onClose}
                    className="text-white hover:bg-blue-700 rounded-full p-2 transition-all duration-200"
                    aria-label="Close chat"
                >
                    ✕
                </button>
            </div>

            {/* Chat Messages */}
            <div
                className="flex-1 p-4 overflow-y-auto bg-gray-50 dark:bg-gray-800"
                onScroll={handleScroll}
            >
                {loading ? (
                    <div className="text-center text-gray-500">Loading messages...</div>
                ) : messages.length === 0 ? (
                    <div className="text-center text-gray-500">No messages yet.</div>
                ) : (
                    messages.map((msg) => {
                        const isSentByUser = msg.sender?._id === userId;
                        const messageTime = new Date(msg.date).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                        });

                        return (
                            <div
                                key={msg._id}
                                className={`flex mb-4 ${
                                    isSentByUser ? 'justify-end' : 'justify-start'
                                }`}
                            >
                                <div
                                    className={`relative max-w-xs px-4 py-3 rounded-lg shadow-lg ${
                                        isSentByUser
                                            ? 'bg-blue-500 text-white'
                                            : 'bg-white text-gray-800'
                                    }`}
                                >
                                    <p className="text-sm">{msg.content}</p>
                                    <span className="text-xs text-gray-400 mt-1 block text-right">
                                        {messageTime}
                                    </span>
                                </div>
                            </div>
                        );
                    })
                )}
                <div ref={messagesEndRef} />
            </div>

            {/* Message Input */}
            <form onSubmit={sendMessage} className="p-4 border-t border-gray-200 dark:border-gray-700 bg-gray-100 dark:bg-gray-900">
                <div className="flex">
                    <input
                        type="text"
                        placeholder="Type your message..."
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        className="flex-grow px-4 py-2 mr-2 border border-gray-300 dark:border-gray-700 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 focus:outline-none"
                    >
                        Send
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ChatWindow;
