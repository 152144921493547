import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import ResetPassword from './components/ResetPassword';

// Import the components
import InfluencerDashboard from './pages/InfluencerDashboard';
import DashboardContent from './components/DashboardContent';
import InfluencerProfileForm from './components/InfluencerProfileForm';
import JobApplications from './components/JobApplications';
import JobsPage from './pages/JobsPage'; // Ensure the correct path

// Remove the redundant import (if not used elsewhere)
// import AppliedJobs from './components/AppliedJobs'; 

const App = () => (
    <Router>
        <Routes>
            {/* Authentication Routes */}
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ResetPassword />} />

            {/* Influencer Dashboard Routes */}
            <Route path="/influencer/dashboard" element={<InfluencerDashboard />}>
                <Route path="" element={<DashboardContent />} />
                <Route path="profile" element={<InfluencerProfileForm />} />
                <Route path="jobs" element={<JobsPage />} /> {/* Updated this line */}
                <Route path="applied-jobs" element={<JobApplications />} />
            </Route>

           

            {/* Remove the standalone JobsPage route if it's no longer needed */}
            {/* <Route path="/all-job" element={<JobsPage />} /> */}
        </Routes>
    </Router>
);

export default App;
