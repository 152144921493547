// src/pages/Register.js

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('Brand'); // Default account type
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // Function to set the token in axios headers for future requests
  const setAuthToken = (token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  // Handle registration
  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post('https://backendinf.vercel.app/api/auth/register', {
        name,
        email,
        password,
        type,
      });
      setAuthToken(data.token); // Set the token in axios headers
      localStorage.setItem('token', data.token); // Store the token in localStorage

      // Redirect based on user type
      if (data.userType === 'Brand') {
        navigate('https://onlybrand.vercel.app'); // Redirect to Brand dashboard
      } else if (data.userType === 'Influencer') {
        navigate('/influencer/dashboard'); // Redirect to Influencer dashboard
      } else {
        navigate('/dashboard'); // Default dashboard
      }
    } catch (err) {
      setMessage('Error registering');
    }
  };

  return (
    <section className="bg-gray-100 min-h-screen flex box-border justify-center items-center">
      <div className="bg-[#dfa674] rounded-2xl flex max-w-3xl p-5 items-center">
        <div className="md:w-1/2 px-8">
          <h2 className="font-bold text-3xl text-[#002D74]">Register</h2>
          <p className="text-sm mt-4 text-[#002D74]">Create an account to get started.</p>

          {message && <p className="text-red-500 text-center">{message}</p>}

          <form onSubmit={handleRegister} className="flex flex-col gap-4">
            <input
              className="p-2 mt-8 rounded-xl border"
              type="text"
              name="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              className="p-2 rounded-xl border"
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              className="p-2 rounded-xl border"
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <select
              className="p-2 rounded-xl border"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="Brand">Brand</option>
              <option value="Influencer">Influencer</option>
            </select>

            <button
              className="bg-[#002D74] text-white py-2 rounded-xl hover:scale-105 duration-300 hover:bg-[#206ab1] font-medium"
              type="submit"
            >
              Register
            </button>
          </form>

          <div className="mt-4 text-sm flex justify-between items-center">
            <Link to="/login" className="text-[#002D74] hover:underline">
              Already have an account? Login
            </Link>
          </div>
        </div>
        <div className="md:block hidden w-1/2">
          <img
            className="rounded-2xl max-h-[1600px]"
            src="https://images.unsplash.com/photo-1552010099-5dc86fcfaa38?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHwxfHxmcmVzaHxlbnwwfDF8fHwxNzEyMTU4MDk0fDA&ixlib=rb-4.0.3&q=80&w=1080"
            alt="register form"
          />
        </div>
      </div>
    </section>
  );
};

export default Register;
