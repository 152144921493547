import React, { useState, useEffect } from 'react';
import { applyForJob, getAllInfluencerApplications } from '../api'; // Adjust the import path as needed

const JobApplications = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const applicationsData = await getAllInfluencerApplications();
        setApplications(applicationsData);
      } catch (err) {
        setError('Failed to fetch applications, please try again later.');
        console.error('Error fetching applications:', err.response ? err.response.data : err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, []);

  const handleApply = async (jobId) => {
    try {
      await applyForJob(jobId);
      alert('Successfully applied for the job!');
      // Update the status to "Pending" for the re-applied job
      setApplications((prevApplications) =>
        prevApplications.map((app) =>
          app.job._id === jobId ? { ...app, status: 'Pending' } : app
        )
      );
    } catch (err) {
      alert('Failed to apply for the job.');
      console.error('Error applying for job:', err.response ? err.response.data : err.message);
    }
  };

  if (loading) {
    return <div className="text-center py-6 mt-20">Loading applications...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center py-6 mt-20">{error}</div>;
  }

  if (!Array.isArray(applications) || applications.length === 0) {
    return <div className="p-4 text-center text-gray-600 mt-20">No applications found.</div>;
  }

  return (
    <div className="p-8 bg-white shadow-md rounded-lg mt-20">
      <h2 className="text-2xl font-semibold mb-4">Your Applied Jobs</h2>
      <table className="min-w-full bg-white border border-gray-300 rounded-lg">
        <thead>
          <tr>
            <th className="py-3 px-4 border-b bg-gray-50 text-left text-sm font-medium text-gray-700">
              Job Title
            </th>
            <th className="py-3 px-4 border-b bg-gray-50 text-left text-sm font-medium text-gray-700">
              Status
            </th>
            <th className="py-3 px-4 border-b bg-gray-50 text-left text-sm font-medium text-gray-700">
              Date Applied
            </th>
            <th className="py-3 px-4 border-b bg-gray-50 text-left text-sm font-medium text-gray-700">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {applications.map((application) => (
            <tr key={application._id}>
              <td className="py-3 px-4 border-b text-sm text-gray-700">
                {application.job.title}
              </td>
              <td
                className={`py-3 px-4 border-b text-sm ${
                  application.status === 'Approved'
                    ? 'text-green-600 font-bold'
                    : application.status === 'Rejected'
                    ? 'text-red-600 font-bold'
                    : 'text-gray-700'
                }`}
              >
                {application.status}
              </td>
              <td className="py-3 px-4 border-b text-sm text-gray-700">
                {new Date(application.dateApplied).toLocaleDateString()}
              </td>
              <td className="py-3 px-4 border-b text-sm text-gray-700">
                {application.status === 'Pending' ? (
                  <span className="text-gray-500">Applied</span>
                ) : (
                  <button
                    onClick={() => handleApply(application.job._id)}
                    className="text-blue-500 hover:underline"
                  >
                    Reapply
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default JobApplications;
