import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaUser, FaBriefcase, FaEnvelope } from 'react-icons/fa';

const Sidebar = () => {
    return (
        <nav
            aria-label="side bar"
            className="flex-none flex flex-col items-center text-center bg-teal-900 text-gray-400 border-r"
        >
            {/* Logo Section */}
            <div className="h-16 flex items-center w-full">
                <img
                    className="h-6 w-6 mx-auto"
                    src="https://raw.githubusercontent.com/bluebrown/tailwind-zendesk-clone/master/public/assets/leaves.png"
                    alt="Logo"
                />
            </div>

            {/* Sidebar Menu Items */}
            <ul>
                {/* Dashboard */}
                <li>
                    <Link
                        title="Dashboard"
                        to="/influencer/dashboard"
                        className="h-16 px-6 flex items-center text-white bg-teal-700 w-full"
                    >
                        <FaHome className="mx-auto" />
                    </Link>
                </li>

               

                {/* Profile */}
                <li>
                    <Link
                        title="Profile"
                        to="/influencer/dashboard/profile"
                        className="h-16 px-6 flex items-center hover:text-white w-full"
                    >
                        <FaUser className="mx-auto" />
                    </Link>
                </li>

                {/* Jobs */}
                <li>
                    <Link
                        title="Jobs"
                        to="/influencer/dashboard/jobs"
                        className="h-16 px-6 flex items-center hover:text-white w-full"
                    >
                        <FaBriefcase className="mx-auto" />
                    </Link>
                </li>

                {/* Applications */}
                <li>
                    <Link
                        title="Applications"
                        to="/influencer/dashboard/applied-jobs"
                        className="h-16 px-6 flex items-center hover:text-white w-full"
                    >
                        <FaBriefcase className="mx-auto" />
                    </Link>
                </li>

                
            </ul>

            {/* Footer Section */}
            <div className="mt-auto h-16 flex items-center w-full">
                <img
                    style={{ filter: 'invert(85%)' }}
                    className="h-8 w-10 mx-auto"
                    src="https://raw.githubusercontent.com/bluebrown/tailwind-zendesk-clone/master/public/assets/chi.png"
                    alt="Footer Icon"
                />
            </div>
        </nav>
    );
};

export default Sidebar;
