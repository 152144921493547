import React, { useEffect, useState } from 'react';
import { getProfile, getAllInfluencerApplications } from '../api';
import ChatWindow from './ChatWindow'; // Adjust the path as necessary

const DashboardContent = () => {
  const [profile, setProfile] = useState({});
  const [appliedJobs, setAppliedJobs] = useState([]);

  // State variables for ChatWindow
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);

  useEffect(() => {
    // Fetch user profile and applications data from the API
    const fetchDashboardData = async () => {
      try {
        // Fetch Profile Data
        const profileResponse = await getProfile();
        setProfile(profileResponse);

        // Fetch Applied Jobs
        const jobsResponse = await getAllInfluencerApplications();
        setAppliedJobs(jobsResponse);
      } catch (error) {
        console.error('Error fetching dashboard data', error);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <div className="mt-20 px-6 py-8 bg-gray-100 min-h-screen">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">
          Welcome, {profile.name}
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Profile Summary Section */}
          <div className="bg-white shadow-md rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">
              Profile Summary
            </h2>
            <p className="text-gray-600">
              <strong>Email:</strong> {profile.email}
            </p>
            <p className="text-gray-600">
              <strong>Phone:</strong> {profile.phone}
            </p>

            {/* Dummy Instagram Data */}
            <div className="mt-6">
              <h3 className="font-semibold text-gray-700">Instagram Stats</h3>
              <p className="text-gray-600">
                <strong>Followers:</strong> 10,000
              </p>
              <p className="text-gray-600">
                <strong>Average Likes:</strong> 500
              </p>
              <p className="text-gray-600">
                <strong>Average Comments:</strong> 50
              </p>
            </div>
          </div>

          {/* Latest Applied Jobs Section */}
          <div className="bg-white shadow-md rounded-lg p-6 md:col-span-2">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">
              Latest Applied Jobs
            </h2>
            {appliedJobs.length > 0 ? (
              <ul className="divide-y divide-gray-300">
                {appliedJobs.slice(0, 5).map((job) => (
                  <li
                    key={job._id}
                    className="py-4 flex justify-between items-center"
                  >
                    <div>
                      <h3 className="text-lg font-medium text-gray-800">
                        {job.job.title}
                      </h3>
                      <p className="text-sm text-gray-600">
                        {job.brandName || job.job.brand?.name}
                      </p>
                      <span
                        className={`text-xs font-semibold px-2 py-1 rounded ${
                          job.status === 'Approved'
                            ? 'bg-green-100 text-green-600'
                            : 'bg-yellow-100 text-yellow-600'
                        }`}
                      >
                        {job.status}
                      </span>
                    </div>
                    {/* Conditional Rendering of Chat Button or Icon */}
                    {job.status !== 'Pending' ? (
                      <button
                        className="text-blue-500 hover:underline"
                        onClick={() => {
                          setSelectedJobId(job.job._id);
                          setSelectedInfluencer({
                            _id: profile._id,
                            name: profile.name,
                          });
                          setIsChatOpen(true);
                        }}
                      >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8h2a2 2 0 012 2v8l-4-4H7a2 2 0 01-2-2V6a2 2 0 012-2h8"
                        />
                      </svg>
                      </button>
                    ) : (
                      /* Display chat icon when status is Pending */
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8h2a2 2 0 012 2v8l-4-4H7a2 2 0 01-2-2V6a2 2 0 012-2h8"
                        />
                      </svg>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600">No jobs applied for yet.</p>
            )}
          </div>
        </div>
      </div>

      {/* ChatWindow Modal */}
      {isChatOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-md">
            <ChatWindow
              influencer={selectedInfluencer}
              jobId={selectedJobId}
              onClose={() => setIsChatOpen(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardContent;
