import axios from 'axios';

// Set up a base instance of axios
const api = axios.create({
    baseURL: 'https://backendinf.vercel.app/api', // Adjust the base URL as needed
});

// Function to set the JWT token in the headers for authorization
export const setAuthToken = token => {
    if (token) {
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete api.defaults.headers.common['Authorization'];
    }
};

// Example of setting the token on initial load if it's available in localStorage
const token = localStorage.getItem('token');
if (token) setAuthToken(token);

// Auth Functions
export const loginUser = async (email, password) => {
    const response = await api.post('/auth/login', { email, password });
    setAuthToken(response.data.token); // Automatically set token after login
    localStorage.setItem('token', response.data.token); // Save token to localStorage
    return response.data;
};

export const registerUser = async (userData) => {
    const response = await api.post('/auth/register', userData);
    return response.data;
};

// Influencer Routes
export const getInfluencerProfile = async () => {
    const response = await api.get('/influencer/profile');
    return response.data;
};

export const updateInfluencerProfile = async (profileData) => {
    const response = await api.put('/influencer/profile', profileData);
    return response.data;
};

export const getInfluencerCredits = async () => {
    const response = await api.get('/influencer/credits');
    return response.data;
};

export const getAppliedJobs = async () => {
    const response = await api.get('/influencer/applications');
    return response.data;
};

export const applyForJob = async (jobId) => {
    const response = await api.post(`/influencer/apply/${jobId}`);
    return response.data;
};

export const getAllInfluencerApplications = async () => {
    const response = await api.get('/influencer/applications');
    return response.data;
};

// Jobs Functions
export const getAllJobs = async () => {
    const response = await api.get('/jobs/all');
    return response.data;
};

export const postJob = async (payload) => {
    const response = await api.post('/jobs', payload);
    return response.data;
};

export const postJoblist = async (payload) => {
    const response = await api.post('/jobs/brand', payload);
    return response.data;
  };
  
export const getJobsForBrand = async () => {
    const response = await api.get('/jobs/brand');
    return response.data;
  };
  
export const getJobById = async (jobId) => {
    const response = await api.get(`/jobs/${jobId}`);
    return response.data;
  };



// Super Admin and Admin Functions
export const approveUser = async (userId) => {
    const response = await api.post(`/admin/approve/${userId}`);
    return response.data;
};

// Profile and User-Specific Functions
export const getProfile = async () => {
    const response = await api.get('/influencer/profile');
    return response.data;
};

export const updateProfile = async (profileData) => {
    const response = await api.put('/influencer/profile', profileData);
    return response.data;
};

export const getAccountStatus = async () => {
    const response = await api.get('/users/account-status');
    return response.data;
};

// Notifications and Credits Routes
export const getNotifications = async () => {
    const response = await api.get('/notifications');
    return response.data;
};

export const getUserCredits = async () => {
    const response = await api.get('/credits');
    return response.data;
};


export const postMessage = async ({ receiverId, jobId, content }) => {
    try {
        const response = await api.post('/message', {
            receiverId,
            jobId,
            content
        });
        return response.data;
    } catch (error) {
        console.error('Error sending message:', error);
        throw error; // Rethrow the error to be handled by the caller
    }
};



export const getMessage = async (jobId) => {
    try {
        const response = await api.get(`/message/${jobId}`);
        return response.data.data; // Return the 'data' array
    } catch (error) {
        console.error('Error fetching messages:', error);
        throw error;
    }
};



export default api;
