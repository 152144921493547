import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import { Outlet, useLocation } from 'react-router-dom';  // Outlet will render nested routes

const InfluencerDashboard = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const location = useLocation(); // To check the current route

    return (
        <div className={`flex h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
            {/* Sidebar */}
            <Sidebar isSidebarOpen={isSidebarOpen} />

            {/* Main content with header */}
            <div className="flex-1 flex flex-col">
                <Navbar setIsSidebarOpen={setIsSidebarOpen} darkMode={darkMode} setDarkMode={setDarkMode} />

                {/* Check if it's the base route, render nothing or placeholder */}
                <div className="p-6 flex-1 overflow-auto">
                    {location.pathname === '/influencer/dashboard/' ? (
                        <div> {/* This is just an empty div for now */}
                            <h1>Welcome to the Influencer Dashboard</h1>
                        </div>
                    ) : (
                        <Outlet />  // Render nested routes like /profile or others
                    )}
                </div>
            </div>
        </div>
    );
};

export default InfluencerDashboard;
