import React, { useState } from 'react';
import axios from 'axios';

const InfluencerProfileForm = () => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        socialMediaPlatforms: [],
        instagramFollowers: '',
        avgComments: '',
        avgLikes: '',
        reelPlays: '',
        categories: [],
        budget: 1000,
    });
    const [loading, setLoading] = useState(false); // Loading state for submission
    const [error, setError] = useState(null); // Error state

    const socialMediaOptions = ['Instagram', 'YouTube', 'TikTok', 'Facebook', 'Twitter'];
    const categoryOptions = ['Fashion', 'Beauty', 'Fitness', 'Travel', 'Technology', 'Food'];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleMultiSelectChange = (e, field) => {
        const { value, checked } = e.target;
        setFormData({
            ...formData,
            [field]: checked
                ? [...formData[field], value]
                : formData[field].filter((item) => item !== value),
        });
    };

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post('http://localhost:5000/api/influencer/profile', formData, {
                headers: { 'x-auth-token': token },
            });

            console.log('Profile updated:', response.data);
            // You can redirect or show success feedback here
        } catch (err) {
            console.error('Error submitting profile:', err);
            setError('Failed to submit profile. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100 dark:bg-gray-800">
            <div className="w-full max-w-lg bg-white dark:bg-gray-900 rounded-2xl p-6 shadow-lg space-y-6">
                <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200 text-center">
                    Influencer Profile Form
                </h2>

                {/* Step Indicator */}
                <div className="flex justify-between mb-4">
                    <div className={`w-full h-2 ${step >= 1 ? 'bg-blue-500' : 'bg-gray-300'} rounded`}></div>
                    <div className={`w-full h-2 ${step >= 2 ? 'bg-blue-500' : 'bg-gray-300'} rounded ml-2`}></div>
                    <div className={`w-full h-2 ${step >= 3 ? 'bg-blue-500' : 'bg-gray-300'} rounded ml-2`}></div>
                </div>

                <form onSubmit={handleSubmit}>
                    {/* Step 1: Social Media Platforms */}
                    {step === 1 && (
                        <div>
                            <h3 className="text-xl font-semibold mb-4">Social Media Platforms</h3>
                            <p className="mb-2 text-gray-600 dark:text-gray-300">Select your primary social media platforms:</p>
                            <div className="space-y-2">
                                {socialMediaOptions.map((platform) => (
                                    <label key={platform} className="block">
                                        <input
                                            type="checkbox"
                                            value={platform}
                                            onChange={(e) => handleMultiSelectChange(e, 'socialMediaPlatforms')}
                                            checked={formData.socialMediaPlatforms.includes(platform)}
                                        />
                                        <span className="ml-2 text-gray-700 dark:text-gray-300">{platform}</span>
                                    </label>
                                ))}
                            </div>
                            <div className="mt-4 flex justify-between">
                                <button type="button" className="text-indigo-500" onClick={nextStep}>
                                    Next
                                </button>
                            </div>
                        </div>
                    )}

                    {/* Step 2: Instagram and Engagement */}
                    {step === 2 && (
                        <div>
                            <h3 className="text-xl font-semibold mb-4">Instagram Details</h3>
                            <div className="mb-4">
                                <label className="block text-gray-700 dark:text-gray-300">Number of Instagram Followers</label>
                                <input
                                    type="number"
                                    name="instagramFollowers"
                                    value={formData.instagramFollowers}
                                    onChange={handleInputChange}
                                    className="w-full p-2 rounded-lg bg-gray-100 dark:bg-gray-800 border dark:border-gray-600 focus:outline-none"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 dark:text-gray-300">Average Comments</label>
                                <input
                                    type="number"
                                    name="avgComments"
                                    value={formData.avgComments}
                                    onChange={handleInputChange}
                                    className="w-full p-2 rounded-lg bg-gray-100 dark:bg-gray-800 border dark:border-gray-600 focus:outline-none"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 dark:text-gray-300">Average Likes</label>
                                <input
                                    type="number"
                                    name="avgLikes"
                                    value={formData.avgLikes}
                                    onChange={handleInputChange}
                                    className="w-full p-2 rounded-lg bg-gray-100 dark:bg-gray-800 border dark:border-gray-600 focus:outline-none"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 dark:text-gray-300">Estimated Reel Plays</label>
                                <input
                                    type="number"
                                    name="reelPlays"
                                    value={formData.reelPlays}
                                    onChange={handleInputChange}
                                    className="w-full p-2 rounded-lg bg-gray-100 dark:bg-gray-800 border dark:border-gray-600 focus:outline-none"
                                />
                            </div>
                            <div className="mt-4 flex justify-between">
                                <button type="button" className="text-indigo-500" onClick={prevStep}>
                                    Back
                                </button>
                                <button type="button" className="text-indigo-500" onClick={nextStep}>
                                    Next
                                </button>
                            </div>
                        </div>
                    )}

                    {/* Step 3: Influencer Category & Budget */}
                    {step === 3 && (
                        <div>
                            <h3 className="text-xl font-semibold mb-4">Category & Budget</h3>
                            <div className="mb-4">
                                <label className="block text-gray-700 dark:text-gray-300">Influencer Categories</label>
                                <div className="space-y-2">
                                    {categoryOptions.map((category) => (
                                        <label key={category} className="block">
                                            <input
                                                type="checkbox"
                                                value={category}
                                                onChange={(e) => handleMultiSelectChange(e, 'categories')}
                                                checked={formData.categories.includes(category)}
                                            />
                                            <span className="ml-2 text-gray-700 dark:text-gray-300">{category}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>

                            <div className="mb-4">
                                <label className="block text-gray-700 dark:text-gray-300">Estimated Budget</label>
                                <input
                                    type="range"
                                    name="budget"
                                    min="100"
                                    max="10000"
                                    step="100"
                                    value={formData.budget}
                                    onChange={handleInputChange}
                                    className="w-full"
                                />
                                <div className="text-center text-gray-700 dark:text-gray-300 mt-2">
                                    ${formData.budget}
                                </div>
                            </div>

                            <div className="mt-4 flex justify-between">
                                <button type="button" className="text-indigo-500" onClick={prevStep}>
                                    Back
                                </button>
                                <button type="submit" className="text-indigo-500" disabled={loading}>
                                    {loading ? 'Submitting...' : 'Submit'}
                                </button>
                            </div>
                        </div>
                    )}

                    {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
                </form>
            </div>
        </div>
    );
};

export default InfluencerProfileForm;
